<template>
  <div class="gridWrapper w-full">
    <div class="left stickyColumn">
      <div class="post-thumb w-full min-h-[330px] rounded-[4px] hover:scale-[1.01] transition-all cursor-pointer">
        <div class="thumbImg lg:h-[100vh] h-[330px] w-full lg:mb-3 mb-6 overflow-hidden rounded-[4px] relative">
          <div class="w-full h-full bg-faintGrey animate-pulse"/>
          <div class="absolute bottom-0 left-0 p-4 w-[-webkit-fill-available] backdrop-blur-[1px]">
            <div class="flex items-center mb-3">
              <span class="font-Rubik block h-4 w-full bg-faintGrey animate-pulse rounded-[3px]"/>
            </div>

            <div class="font-Rubik block h-8 w-full mb-3 bg-faintGrey animate-pulse rounded-[3px]"/>
            <div class="font-Rubik block h-6 w-full bg-faintGrey animate-pulse rounded-[3px]"/>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="block">
        <div class="grid grid-cols-2 gap-[20px] w-full">
          <div
v-for="(_, _index) in dummyItems.length" :key="_index + _"
            class="post-thumb lg:w-[270px] md:max-w-full w-full min-h-[330px] rounded-[4px] hover:scale-[1.01] transition-all cursor-pointer block mb-4">

            <div class="thumbImg h-[330px] w-full mb-3 overflow-hidden rounded-[4px]">
              <div class="w-full h-full bg-faintGrey animate-pulse"/>
            </div>
            <div class="flex items-center justify-between flex-wrap gap-2 mb-3">
              <span class="font-Rubik block h-4 w-[47%] bg-faintGrey animate-pulse rounded-[3px]"/>
              <span class="font-Rubik block h-4 w-[47%] bg-faintGrey animate-pulse rounded-[3px]"/>
            </div>

            <div class="skel-title font-Rubik block h-8 mb-3 w-full bg-faintGrey animate-pulse rounded-[3px]"/>
            <div class="skel-title font-Rubik block h-8 w-full mb-3 bg-faintGrey animate-pulse rounded-[3px]"/>
            <div class="font-Rubik block h-3 w-full mb-3 bg-faintGrey animate-pulse rounded-[3px]"/>
            <div class="font-Rubik block h-3 w-[80%] mb-3 bg-faintGrey animate-pulse rounded-[3px]"/>
            <div class="font-Rubik block h-3 w-[97%] mb-3 bg-faintGrey animate-pulse rounded-[3px]"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const dummyItems = ref([
  { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }
])

</script>

<style></style>