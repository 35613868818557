<template>
  <main class="home">
    <section class="insights py-8 max-w-[1180px] mx-auto px-[20px]">
      <div
        class="head left flex items-center justify-between w-full border-b-[1px] border-faintGrey dark:border-gray-700 py-3 mb-6">
        <h3 class="md:text-[22px] text-[18px] font-medium leading-[42.66px]">Insights</h3>
        <NuxtLink to="/insights">
          <button class="font-Playfair text-[14px] leading-[20px] text-greyText font-light">
            View all
          </button>
        </NuxtLink>
      </div>
      <template v-if="!stateInsightsLoading">
        <template v-if="stateInsights.length > 0">
          <LazyHomeCategoryArticleLayout category="insights" :data="stateInsights" :loading="stateInsightsLoading" />
        </template>
      </template>
      <template v-else>
        <!-- Loading skeleton -->
        <LazyHomeGridCardSkeleton />
      </template>
    </section>

    <section class="founder-profiles py-8 max-w-[1180px] mx-auto px-[20px]">
      <div
        class="head left flex items-center justify-between w-full border-b-[1px] border-faintGrey dark:border-gray-700 py-3 mb-6">
        <h3 class="md:text-[22px] text-[18px] font-medium leading-[42.66px]">Founders</h3>
        <NuxtLink to="/founder-profiles">
          <button class="font-Playfair text-[14px] leading-[20px] text-greyText font-light">
            View all
          </button>
        </NuxtLink>
      </div>
      <template v-if="stateFounders.length > 0">
        <LazyHomeCategoryArticleLayout category="founder-profiles" :data="stateFounders"
          :loading="stateFoundersLoading" />
      </template>
      <template v-else>
        <!-- Loading skeleton -->
        <HomeGridCardSkeleton />
      </template>
    </section>

    <section class="recommended-reads relative py-8 max-w-[1180px] mx-auto px-[20px]">
      <div
        class="head flex items-center justify-between w-full border-b-[1px] border-faintGrey dark:border-gray-700 py-3 mb-6">
        <h3 class="md:text-[22px] text-[18px] font-medium leading-[42.66px]">
          Recommended Reads
        </h3>
        <router-link to="/recommended-reads">
          <button class="font-Playfair text-[14px] leading-[20px] text-greyText font-light">
            View all
          </button>
        </router-link>
      </div>

      <template v-if="stateRecommendedReads.length > 0">
        <LazyHomeCategoryArticleLayout category="recommended-reads" :data="stateRecommendedReads"
          :loading="stateRecommendedReadsLoading" />
      </template>
      <template v-else>
        <!-- Loading skeleton -->
        <HomeGridCardSkeleton />
      </template>
    </section>

    <section class="news py-8 max-w-[1180px] mx-auto px-[20px]">
      <div
        class="head left flex items-center justify-between w-full border-b-[1px] border-faintGrey dark:border-gray-700 py-3 mb-6">
        <h3 class="md:text-[22px] text-[18px] font-medium leading-[42.66px]">News</h3>
        <NuxtLink to="/news">
          <button class="font-Playfair text-[14px] leading-[20px] text-greyText font-light">
            View all
          </button>
        </NuxtLink>
      </div>

      <template v-if="stateNews.length > 0">
        <LazyHomeCategoryArticleLayout category="news" :data="stateNews" :loading="stateNewsLoading" />
      </template>
      <template v-else>
        <!-- Loading skeleton -->
        <HomeGridCardSkeleton />
      </template>
    </section>
  </main>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useInsightsStore } from '../stores/insights'
import { useFounderProfilesStore } from '../stores/founderProfiles'
import { useRecommendedReadsStore } from '../stores/recommendedReads'
import { useNewsStore } from '../stores/news'

useHead(() => ({
  title: 'Chronicles - Insights',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content:
        'Recommended reads for you. Dig in!',
    },
    { hid: 'og:title', property: 'og:title', content: 'Founder Profiles' },
    {
      hid: 'og:description',
      property: 'og:description',
      content:
        'Recommended reads for you. Dig in!',
    },
    { hid: 'og:image', property: 'og:image', content: '' },
    // Add more dynamic meta tags as needed
  ],
}))

const insightsStore = useInsightsStore()
const foundersStore = useFounderProfilesStore()
const recommendedReadsStore = useRecommendedReadsStore()
const newsStore = useNewsStore()

const stateInsights = computed(() => insightsStore.data)
const stateInsightsLoading = computed(() => insightsStore.loading)
const stateFounders = computed(() => foundersStore.data)
const stateFoundersLoading = computed(() => foundersStore.loading)
const stateRecommendedReads = computed(() => recommendedReadsStore.data)
const stateRecommendedReadsLoading = computed(() => recommendedReadsStore.loading)
const stateNews = computed(() => newsStore.data)
const stateNewsLoading = computed(() => newsStore.loading)
const success = ref<{ status: boolean; message: string; complete?: boolean } | null>({
  status: false,
  message: "",
})

onBeforeMount(async () => {
  try {
    const insightsResponse = await insightsStore.fetch()
    const foundersResponse = await foundersStore.fetch()
    const recommendedReadsResponse = await recommendedReadsStore.fetch()
    const newsResponse = await newsStore.fetch()
    if (insightsResponse && foundersResponse && recommendedReadsResponse && newsResponse) {
      success.value = {
        status: true,
        message: '',
        complete: true
      }
    }
  } catch (error) {
    console.error(error, 'Error fetching data from store')
  }
})

</script>

<style></style>
